import React from 'react';
import { Button, Container, Typography, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import logo from '../assets/logoZimowisko.png';

const Home = ({ setRole }) => {
    const navigate = useNavigate();

    const handleOrganizerClick = () => {
        setRole('organizer');
        navigate('/login');
    };

    const handleParticipantClick = () => {
        setRole('participant');
        navigate('/calendar');
    };

    return (
        <Container>
            <Box textAlign="center" mt={5}>
                <Typography
                    variant="h4"
                    gutterBottom
                    sx={{ color: 'white', fontFamily: 'Roboto, serif-serif' }}
                >
                    Wybierz swoją rolę
                </Typography>
                <Button
                    variant="contained"
                    style={{ backgroundColor: '#2a99ce', color: 'black', margin: '10px' }}
                    onClick={handleOrganizerClick}
                >
                    Jestem organizatorem
                </Button>
                <Button
                    variant="contained"
                    style={{ backgroundColor: 'white', margin: '10px', color: 'black' }}
                    onClick={handleParticipantClick}
                >
                    Jestem uczestnikiem
                </Button>
                <Box display="flex" justifyContent="center" mt={8}>
                    <img src={logo} alt="Logo V Zimowiska Enklawy" style={{ maxWidth: '60%', height: 'auto' }} />
                </Box>
            </Box>
        </Container>
    );
};

export default Home;

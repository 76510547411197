import React, { useState } from 'react';
import { TextField, Button, Box, Typography } from '@mui/material';
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { useNavigate } from 'react-router-dom';

const Login = ({ setUser }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const auth = getAuth();

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            setUser(userCredential.user);
            navigate('/calendar');
        } catch (error) {
            setError('Podajesz nieprawidłowe dane.');
            console.error(error);
        }
    };

    return (
        <Box component="form" onSubmit={handleLogin}>
            <TextField
                label="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                fullWidth
                margin="normal"
                style={{ backgroundColor: 'white' }}
            />
            <TextField
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                fullWidth
                margin="normal"
                style={{ backgroundColor: 'white' }}
            />
            {error && (
                <Typography variant="body2" color="error" style={{ marginTop: '10px' }}>
                    {error}
                </Typography>
            )}
            <Button variant="contained" color="primary" type="submit" style={{ marginTop: '20px' }}>
                Login
            </Button>
        </Box>
    );
};

export default Login;

import React, { useState, useEffect } from "react";
import {
  Grid,
  Paper,
  Typography,
  Button,
  MenuItem,
  TextField,
  Box,
} from "@mui/material";
import {
  collection,
  addDoc,
  updateDoc,
  deleteDoc,
  onSnapshot,
  doc,
} from "firebase/firestore";
import { db } from "../firebase";
import { getAuth, signOut } from "firebase/auth";
import WorkshopCard from "./WorkshopCard";
import WorkshopModal from "./WorkshopModal";
import { logUserAction } from "./logActions";
import czwartek from "../assets/czwartek.png";
import piatek from "../assets/piatek.png";
import sobota from "../assets/sobota.png";
import niedziela from "../assets/niedziela.png";

const days = [
  { text: "Czwartek", value: "Czwartek" },
  { text: "Piątek", value: "Piątek" },
  { text: "Sobota", value: "Sobota" },
  { text: "Niedziela", value: "Niedziela" },
];

const dayImages = {
  Czwartek: czwartek,
  Piątek: piatek,
  Sobota: sobota,
  Niedziela: niedziela,
};

const categories = [
  { text: "organizatorzy", value: "organizatorzy" },
  { text: "poi", value: "poi" },
  { text: "kije", value: "kije" },
  { text: "hula", value: "hula" },
  { text: "wachlarze", value: "wachlarze" },
  { text: "żonglerka", value: "żonglerka" },
  { text: "inne kuglarskie", value: "inne kuglarskie" },
  { text: "inne niekuglarskie", value: "inne niekuglarskie" },
  { text: "W.U.R", value: "W.U.R" },
];

const Calendar = ({ role }) => {
  const [workshops, setWorkshops] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [workshopToEdit, setWorkshopToEdit] = useState(null);
  const [selectedDay, setSelectedDay] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, "workshops"), (snapshot) => {
      const fetchedWorkshops = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setWorkshops(sortWorkshops(fetchedWorkshops));
    });

    return () => unsubscribe();
  }, []);

  const sortWorkshops = (workshops) => {
    return workshops.sort((a, b) => {
      const aTime = new Date(`1970-01-01T${a.startTime}:00`);
      const bTime = new Date(`1970-01-01T${b.startTime}:00`);
      return aTime - bTime;
    });
  };

  const handleOpenModal = () => setModalOpen(true);
  const handleCloseModal = () => {
    setModalOpen(false);
    setWorkshopToEdit(null);
  };

  const addWorkshop = async (newWorkshop) => {
    const user = getAuth().currentUser;
    const docRef = await addDoc(collection(db, "workshops"), newWorkshop);
    await logUserAction("add", { ...newWorkshop, id: docRef.id }, user);
  };

  const editWorkshop = (workshop) => {
    setWorkshopToEdit(workshop);
    handleOpenModal();
  };

  const saveWorkshop = async (updatedWorkshop) => {
    const user = getAuth().currentUser;
    const previousWorkshop = workshops.find((w) => w.id === updatedWorkshop.id);

    const workshopRef = doc(db, "workshops", updatedWorkshop.id);
    await updateDoc(workshopRef, updatedWorkshop);

    await logUserAction("edit", updatedWorkshop, user, previousWorkshop);
    handleCloseModal();
  };

  const deleteWorkshop = async (id) => {
    const user = getAuth().currentUser;
    const workshopToDelete = workshops.find((w) => w.id === id);
    await deleteDoc(doc(db, "workshops", id));
    await logUserAction("delete", workshopToDelete, user);
  };

  const handleLogout = async () => {
    const auth = getAuth();
    await signOut(auth);
  };

  const handleDayChange = (event) => {
    setSelectedDay(event.target.value);
  };

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  const filteredWorkshops = workshops.filter((workshop) => {
    return (
      (!selectedDay || workshop.day === selectedDay) &&
      (!selectedCategory || workshop.category === selectedCategory)
    );
  });

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          marginTop: 2,
          marginBottom: 2,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: 1,
        }}
      >
        <Typography
          variant="h4"
          component="h1"
          sx={{ color: "#fff", fontFamily: "Roboto, serif-serif" }}
        >
          V Zimowisko Enklawy
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexWrap: "wrap",
          gap: 2,
          marginBottom: 2,
        }}
      >
        {role === "organizer" && (
          <>
            <Button
              variant="contained"
              style={{ backgroundColor: "#f6c445", color: "black" }}
              onClick={handleOpenModal}
            >
              Dodaj warsztat
            </Button>
            <Button
              variant="contained"
              style={{ backgroundColor: " #6B21BF" }}
              onClick={handleLogout}
            >
              Wyloguj
            </Button>
          </>
        )}
        <TextField
          select
          label="Dzień"
          value={selectedDay}
          onChange={handleDayChange}
          style={{ minWidth: "150px", backgroundColor: "white" }}
        >
          <MenuItem value="">
            <em>Wszystkie dni</em>
          </MenuItem>
          {days.map((day) => (
            <MenuItem key={day.value} value={day.value}>
              {day.text}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Kategoria"
          value={selectedCategory}
          onChange={handleCategoryChange}
          style={{ minWidth: "150px", backgroundColor: "white" }}
        >
          <MenuItem value="">
            <em>Wszystkie kategorie</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.value} value={category.value}>
              {category.text}
            </MenuItem>
          ))}
        </TextField>
      </Box>
      <Grid container spacing={2} style={{ flexGrow: 1 }}>
        {days.map((day) => (
          <Grid item xs={12} sm={6} md={3} key={day.value}>
            <Box
              sx={{
                backgroundColor: "#2e3440",
                padding: "0",
                marginBottom: "16px",
                position: "sticky",
                top: 0,
                zIndex: 1000,
              }}
            >
              <Box
                component="img"
                src={dayImages[day.text]}
                alt={day.text}
                sx={{
                  padding: "0",
                  margin: "0",
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                  display: "block",
                }}
              />
            </Box>
            <Paper
              style={{
                textAlign: "center",
                height: "100%",
                backgroundColor: "#222222",
                color: "#fff",
              }}
            >
              {filteredWorkshops
                .filter((workshop) => workshop.day === day.value)
                .map((workshop, index) => (
                  <WorkshopCard
                    key={index}
                    workshop={workshop}
                    role={role}
                    onEdit={
                      role === "organizer" ? () => editWorkshop(workshop) : null
                    }
                    onDelete={
                      role === "organizer"
                        ? () => deleteWorkshop(workshop.id)
                        : null
                    }
                  />
                ))}
            </Paper>
          </Grid>
        ))}
      </Grid>
      <WorkshopModal
        open={modalOpen}
        handleClose={handleCloseModal}
        saveWorkshop={workshopToEdit ? saveWorkshop : addWorkshop}
        workshopToEdit={workshopToEdit}
      />
    </>
  );
};

export default Calendar;
